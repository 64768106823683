<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="编辑新闻"/>
		<NewForm :newsForm="form" @eventForm="handleGetForm" class="purForm" />
	</div>
</template>

<script>
	import NewForm from '@/components/News/Form'
	export default {
		data() {
			return {
				form: {}
			}
		},
		created() {
			this.$axios.post('/getnews', {
				id: this.$route.query.id
			}).then(data => {
				this.form = data
			})
		},
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('修改成功')
					this.$router.push('/news')
				})
			}
		},
		components: {
			NewForm
		}
	}
</script>
